import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "src/app/providers/login.service";
import { ToasterService } from "src/app/providers/toaster.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  submitted = false;
  role = localStorage.getItem("userRole");
  userEmail = localStorage.getItem("userEmail");
  public isMenuCollapsed = true;
  roleList = ["USER"];
  companyList = ["Reinvision-Client"];
  private readonly name_pattern = "^[a-zA-Z]+$"; // Only alphabets for username pattern
  unamePattern = "^[a-z0-9_-]{4,15}$"; // alphanumeric pattern for username
  // private readonly email_pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  private readonly email_pattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  showLoader: boolean;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  show_button1: Boolean = false;
  show_eye1: Boolean = false;
  alert: boolean = false;
  initialValuesSignupForm: any;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    // this.RemoveAddControls();
  }

  initForm() {
    this.signupForm = this.formBuilder.group(
      {
        userName: [
          "",
          [
            Validators.required,
            Validators.minLength(4),
            Validators.pattern(this.name_pattern),
          ],
        ],
        email: [
          "",
          [Validators.required, Validators.pattern(this.email_pattern)],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            ),
          ],
        ],
        confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
        gender: ["MALE", Validators.required],
        tnc: [false, Validators.requiredTrue],
        role: ["USER", Validators.required],
        companyName: ["XR Connect", Validators.required],
      },
      {
        validator: mustMatch("password", "confirmPassword"),
      }
    );
    this.initialValuesSignupForm = this.signupForm.value;
  }

  get f() {
    return this.signupForm.controls;
  }

  onSignUp() {
    this.submitted = true;
    // console.log("SignUp formData: ", this.signupForm);

    if (this.signupForm.invalid) {
      return;
    } else {
      // if (this.signupForm.get['role'] === null || this.signupForm.get['role'] === undefined || this.signupForm.get['role'] === '') {
      //   this.signupForm.patchValue({ 'role': 'User' })
      // }
      // if (this.signupForm.get['companyName'] === null || this.signupForm.get['companyName'] === undefined || this.signupForm.get['companyName'] === '') {
      //   this.signupForm.patchValue({ 'companyName': 'Others' })
      // }
      this.showLoader = true;
      this.loginService.registerUser(this.signupForm.value).subscribe(
        (res: any) => {
          console.log("response", res);
          const msg = res.message;
          if (res.status == "fail") {
            this.toasterService.showError(msg, "");
          } else if (res.status == "success") {
            this.toasterService.showSuccess(msg, "");
            // this.router.navigateByUrl("/login");
            this.alert = true;
            this.signupForm.reset(this.initialValuesSignupForm);
          }
          this.showLoader = false;
          this.submitted = false;
        },
        (err) => {
          console.log(err.status, err.message);
          // console.log("oops", err);
          if (err.status == 502) {
            this.toasterService.showError("Server is down.", "");
          } else {
            this.toasterService.showWarning(err.error.message, "");
          }
        }
      );
    }
  }
  onReset() {
    this.submitted = false;
    this.signupForm.reset();
  }
  closeAlert() {
    this.alert = false;
  }
  onNavigateToDashboard() {
    this.router.navigateByUrl("/dashboard/home");
  }

  // Show password Function
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
  enter(event) {
    if (event.keyCode === 13) {
      this.onSignUp();
    }
  }
}

function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
