import { Component, OnInit } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { ToasterService } from "src/app/providers/toaster.service";
declare var $: any;

@Component({
  selector: "app-no-internet-connection",
  templateUrl: "./no-internet-connection.component.html",
  styleUrls: ["./no-internet-connection.component.css"],
})
export class NoInternetConnectionComponent implements OnInit {
  isConnectionAvailable: boolean = navigator.onLine;

  constructor(private httpClient: HttpClient, toaster: ToasterService) {
    // method :1
    // window.addEventListener("online", () => {
    //   this.isConnectionAvailable = true;
    // });

    // window.addEventListener("offline", () => {
    //   this.isConnectionAvailable = false;
    //   alert("Please check your internet connection.");
    // });

    // method :2
    setInterval(async () => {
      var ifConnected = window.navigator.onLine;
      if (ifConnected) {
        // console.log("online");
        return;
      } else {
        alert("Please enable internet connection!");
      }
    }, 3000);
  }

  ngOnInit(): void {}
}
