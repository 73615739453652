import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ForgotEmailComponent } from "./forgot-email/forgot-email.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { UpdatePasswordComponent } from "./update-password/update-password.component";
import { LoginRoutingModule } from "./login-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HomeLatestComponent } from './home-latest/home-latest.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotEmailComponent,
    HomepageComponent,
    UpdatePasswordComponent,
    HomeLatestComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
  ],
  imports: [CommonModule, LoginRoutingModule, ReactiveFormsModule, NgbModule],
})
export class LoginModule {}
