import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "src/app/providers/login.service";
import { ToasterService } from "src/app/providers/toaster.service";

@Component({
  selector: "app-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.css"],
})
export class UpdatePasswordComponent implements OnInit {
  updatePasswordForm: FormGroup;
  submitted = false;
  public isMenuCollapsed = true;
  showLoader: boolean;
  show_button: Boolean = false;
  show_eye: Boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.updatePasswordForm = this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            ),
          ],
        ],
        confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
      },
      {
        validator: this.mustMatch("password", "confirmPassword"),
      }
    );
  }

  get f() {
    return this.updatePasswordForm.controls;
  }
  onSignUp() {
    this.submitted = true;
    // console.log("form", this.updatePasswordForm);

    if (this.updatePasswordForm.invalid) {
      return;
    } else {
      this.showLoader = true;
      this.loginService
        .newPassword(
          this.updatePasswordForm.value.email,
          this.updatePasswordForm.value.password
        )
        .subscribe(
          (res: any) => {
            // console.log("response", res);
            const msg = res.message;
            if (res.status == "fail") {
              this.toasterService.showError(msg, "");
            } else if (res.status == "success") {
              this.toasterService.showSuccess(msg, "");
              this.router.navigateByUrl("/login");
            }
            this.showLoader = false;
            this.submitted = false;
          },
          (err) => {
            console.log(err.status, err.message);
            // console.log("oops", err);
            if (err.status == 502) {
              this.toasterService.showError("Server is down.", "");
            } else if (err.status == 500) {
              this.toasterService.showError("Internal Server Error", "");
            } else {
              this.toasterService.showWarning(err.error.message, "");
            }
          }
        );
      // this.showLoader = false;
    }
  }

  onReset() {
    this.submitted = false;
    this.updatePasswordForm.reset();
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  // Show password Function
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
}
