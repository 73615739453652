export const environment = {
  production: true,

  //  sas keys for xrconnect business
  //azure_sas_key:
    // "sp=racwdl&st=2021-08-30T07:56:49Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=jUW4JyhWQwptl2eNrMVa0l1Mfk%2FYnQMXOZLAfbbdDVQ%3D", // for xrconnect-b2b-dev
    // "sp=racwdl&st=2021-08-25T09:40:16Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=ocNa57qby01rdc0rDxTRTn81lzUORcpM0XiH9OZD0aI%3D", // for xrconnect-b2b-qa

    // 'sp=racwdl&st=2021-08-30T07:57:46Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=TI5Cn8rYUIhhfwauWbyG5PBsuVZijNI1s8bLQY%2FkvcA%3D', // for xrconnect-b2b-prod

    // "sp=racwdl&st=2021-09-03T13:17:43Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=FAgREQxAqZHy09yAP5SMxaHQK1EJ8VeWUv5CBaQCLYQ%3D", // for xrconnect-b2c-qa

    //"sp=racwdli&st=2022-01-03T05:41:59Z&se=2022-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=dhhk4B%2FoEvTGyDDzg%2FDpZYbJ4spyRicVT93M0LDi48k%3D", // staging-server sas key
    //"sp=rcwd&st=2022-02-09T11:05:25Z&se=2022-12-30T19:05:25Z&sv=2020-08-04&sr=c&sig=9INNfDtIPQdMrcR7VrJtC74p1ybXhk05ZZwcXlbMVko%3D",
  //azurePublicSasKey:
    //"sp=racwdl&st=2021-10-11T11:02:03Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=ooJnUqw3fDH%2Fz32VpYaYyA2fQV7QfKXKUP21EzLF7AM%3D",
    //"sp=rcwd&st=2022-02-09T11:05:25Z&se=2022-12-30T19:05:25Z&sv=2020-08-04&sr=c&sig=9INNfDtIPQdMrcR7VrJtC74p1ybXhk05ZZwcXlbMVko%3D",
    
  //  flask api's for xrconnect-b2b

  // BASE_URL: "https://dev.api.business.xrconnect.io", //  dev instance
  // BASE_URL: "https://qa.api.business.xrconnect.io", //  qa instance
  // BASE_URL: "https://api.business.xrconnect.io",      //  prod instance

  // BASE_URL: "https://qa.api.xrconnect.io", //  b2c qa  instance

  BASE_URL: "https://api.staging2.xrconnect.io", //  stage server  instance
  //BASE_URL: "http://127.0.0.1:5000",
  //BASE_URL: "https://api.demo.xrconnect.io",

  //  blob storage
  azure_accountName: "xrconn1", // Account Name Staging
  //azure_accountName: "xrdemo", // Account Name Demo
  //azure_accountKey: "6ENSAfOyVGzLff0sOk8bsAspMnXYdju7OjjZefgshK9y+Xv387ZR0RpYXexCG6/i0bqOIcR6RTu/+AStTUrfDg==",
  //  container names for xrconnect-business(B2B)

  // azure_containerName: "xrconnect-b2b-dev", // dev container for xrconnect-business-dev
  // azure_containerName: "xrconnect-b2b-qa", // qa container for xrconnect-business-qa
  // azure_containerName: "xrconnect-b2b-prod", // prod container for xrconnect-business-prod

  // azure_containerName: "xrconnect-b2c-qa", // qa container for xrconnect-b2c-qa

  azure_containerName: "staging-server", // container for xrconnect-staging-server
  //azure_containerName: "xrconnect-demo",

  // azure public container name
  azurePublicContainerName: "public-container",
  //azurePublicContainerName: "xrconnect-demo",

  // download urls for homepage

  windows_build_url: "https://xrconn1.blob.core.windows.net/dev/Windows.zip",
  quest_build_url: "https://xrconn1.blob.core.windows.net/dev/Quest.zip",
};
