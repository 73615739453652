import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ForgotEmailComponent } from "./forgot-email/forgot-email.component";
import { HomeLatestComponent } from "./home-latest/home-latest.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { LoginComponent } from "./login/login.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { SignupComponent } from "./signup/signup.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { UpdatePasswordComponent } from "./update-password/update-password.component";

const routes: Routes = [
  {
    path: "",
    component: HomeLatestComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "forgot-password",
    component: ForgotEmailComponent,
  },
  {
    path: "updatePassword",
    component: UpdatePasswordComponent,
  },
  {
    path: "privacyPolicy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "termsandconditions",
    component: TermsAndConditionsComponent,
  },
  // {
  //   path:"homepage",
  //   component:HomeLatestComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
