import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "src/app/providers/login.service";
import { ToasterService } from "src/app/providers/toaster.service";

@Component({
  selector: "app-forgot-email",
  templateUrl: "./forgot-email.component.html",
  styleUrls: ["./forgot-email.component.css"],
})
export class ForgotEmailComponent implements OnInit {
  forgotEmailForm: FormGroup;
  submitted = false;
  public isMenuCollapsed = true;
  showLoader: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.forgotEmailForm = this.formBuilder.group({
      forgotEmail: ["", [Validators.required, Validators.email]],
    });
  }
  get f() {
    return this.forgotEmailForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.forgotEmailForm.invalid) {
      return;
    } else {
      this.showLoader = true;
      this.loginService
        .resetPassword(this.forgotEmailForm.value.forgotEmail)
        .subscribe(
          (res: any) => {
            console.log("res", res);
            const msg = res.message;
            if (res.status == "fail") {
              this.toasterService.showError(msg, "");
            } else if (res.status == "success") {
              this.toasterService.showSuccess(msg, "");
              this.router.navigateByUrl("/login");
            }
            this.showLoader = false;
          },
          (err) => {
            console.log(err.status, err.message);
            // console.log("oops", err);
            if (err.status == 502) {
              this.toasterService.showError("Server is down.", "");
            } else {
              this.toasterService.showWarning(err.error.message, "");
            }
          }
        );
    }
  }
}
