import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.css"],
})
export class HomepageComponent implements OnInit {
  public isMenuCollapsed = true;

  constructor(private router: Router) {}

  ngOnInit() {}
  getStarted() {
    this.router.navigateByUrl("login");
  }
}
